.App {
  background-color: #ffffff;
  min-height: 100vh;
}
.overlay {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, 0); */
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 2;
}
.App-logo {
  height: 5vh;
  position: absolute;
  top: 47.5vh;
  left: 48vw;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

/* .header {
  height: 10vh;
  width: 100vw;
  display: flex;
  background-color: white;
  border-bottom: 1px solid #d1d1d6;
  align-items: center;
} */

.header-logo {
  height: 47px;
  width: auto;
  cursor: pointer;
}

.menu-logo {
  height: 28px;
  width: auto;
  margin-left: 30px;
  cursor: pointer;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 3s linear;
  }
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 10px !important;
}

.ant-picker-cell-disabled:before {
  background: none !important;
}

.ant-modal-footer {
  border-top: 0 none !important;
}

.react-tel-input .selected-flag {
  border-radius: 8px 0 0 8px !important;
}
@layer base {
  svg {
    display: inline;
    vertical-align: baseline;
  }
}

p {
  /* margin-bottom: 0px !important; */
}

.ant-image-mask:hover {
  opacity: 0 !important;
}

.carousel .control-dots {
  margin: 0px 0 !important;
}
ol,
ul,
menu {
  list-style: disc;
  margin: 0;
  padding: 0;
  margin-left: 20px;
}

.ant-checkbox-group {
  display: grid;
}
.ant-btn-primary:active,
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  border-color: #169e8e !important;
  background: #169e8e !important;
}
.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none !important;
}

.ant-form-vertical .ant-form-item-label > label::after,
.ant-col-24.ant-form-item-label > label::after,
.ant-col-xl-24.ant-form-item-label > label::after {
  visibility: visible;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  ):after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 20px;
  line-height: 1;
  content: "*";
}
.ant-select-selector {
  height: 45px !important;
  padding: 5px 14px !important;
}
.ant-spin-dot-item {
  background-color: #1bcdb7 !important;
}
.react-tel-input :hover {
  border: 1px solid #40a9ff !important;
  border-radius: 10px !important;
}
.modalTitle {
  font-size: 16px !important;
  color: #000;
  font-weight: 600;
  margin-bottom: 15px !important;
}
.modalCancel {
  color: #3a3a3a;
  border: 1px solid #eaeaea;
  padding: 0px 10px;
  font-size: 14px;
  cursor: pointer;
}
.modalContinue {
  color: #1bcdb7;
  font-weight: 500;
  border: 1px solid #1bcdb7;
  padding: 0px 10px;
  cursor: pointer;
}

.ant-modal-content {
  border-radius: 10px;
}

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  content: none !important;
}
.ant-table {
  overflow: auto !important;
}
.ant-table-selection-col,
.ant-table-selection-column {
  display: none !important;
}
.react-tel-input :hover {
  border: 1px solid #1bcdb7 !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #1bcdb7 !important;
}

.ant-select-selector:hover {
  border-color: #1bcdb7 !important;
}
.ant-select-focused {
  border-color: #1bcdb7 !important;
}
/* .ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #1bcdb7 !important;
} */
.ant-checkbox {
  border-color: #1bcdb7 !important;
}

.ant-picker-calendar .ant-picker-panel {
  border-top: none !important;
}

.ant-menu-submenu-popup .ant-menu-vertical .ant-menu-item,
.ant-menu-submenu-popup .ant-menu-vertical .ant-menu-submenu-title {
  padding-inline: initial !important;
  overflow: visible !important;
}
.ant-select-single.ant-select-lg .ant-select-selector {
  font-size: 14px;
}
.ant-table-wrapper .ant-table-thead > tr > td {
  background-color: #fff;
}
.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: #fff;
  color: #667283;
  /* Subtitle/S3 - Regular - 12 */
  font-family: "Archivo";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 141.667% */
}
.ant-table-wrapper .ant-table-tbody > tr > td {
  color: #25364e;
  text-align: center;
  /* Paragraph/P1 - SemiBold - 13 */
  font-family: "Archivo";
  font-size: 13px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 18px; /* 138.462% */
}
.ant-form-item .ant-form-item-label > label {
  align-items: unset;
}
