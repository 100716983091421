.ant-menu:not(.ant-menu-horizontal) .ant-menu-item.customclass {
  color: #fff; /*Overriden property*/
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected.customclass {
  margin-left: 10px;
  margin-right: 10px;
  color: #1bcdb7;
  background-color: #ffffff; /*Overriden property*/
}

.ant-menu-item a.customclass {
  color: #ffffff;
  background-color: #1bcdb7;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected.customclass {
  margin-left: 10px;
  margin-right: 10px;
  color: #1bcdb7;
  background-color: #ffffff;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none;
}

.ant-layout-sider {
  background: #1bcdb7;
  /* border-right: 1.5px solid #ffff; */
}

.ant-layout-sider-zero-width-trigger {
  display: none;
}

.alertCLass {
  /* background-color: red; */
}

/* .ant-message-notice-content {
  background: #1bcdb7 !important;
  color: #ffffff !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
} */
.ant-message-notice-content {
  background-color: #1bcdb7 !important;
  color: #ffffff !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover {
  color: #ffff !important;
}

.ant-menu-item-selected {
  background-color: #1bcdb7 !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected:after,
.ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-submenu:hover:after
  .ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-submenu:hover::before {
  border-bottom: 0px solid #1bcdb7;
}

.ant-menu > .ant-menu-item:hover,
.ant-menu > .ant-menu-submenu:hover,
.ant-menu > .ant-menu-item-active,
.ant-menu > .ant-menu-submenu-active,
.ant-menu > .ant-menu-item-open,
.ant-menu > .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected {
  border-bottom: 0px solid #1bcdb7;
  /* border-bottom: 2px solid red; */
}

.ant-menu-horizontal,
.ant-menu-item::after,
.ant-menu-submenu::after {
  border: none !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected:after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover:after {
  border-bottom: 0px solid #1bcdb7;
  transition: none 0.5s linear; /* vendorless fallback */
}

.ant-menu > .ant-menu-item:hover,
.ant-menu > .ant-menu-submenu:hover,
.ant-menu > .ant-menu-item-active,
.ant-menu > .ant-menu-submenu-active,
.ant-menu > .ant-menu-item-open,
.ant-menu > .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected {
  /* border-bottom: 0px solid #1bcdb7; */
  /* border-bottom: 2px solid red; */
}

.ant-menu > .ant-menu-item:hover,
.ant-menu > .ant-menu-submenu:hover,
.ant-menu > .ant-menu-item-active,
.ant-menu > .ant-menu-submenu-active,
.ant-menu > .ant-menu-item-open,
.ant-menu > .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected {
  /* border-bottom: none; */
  /* border-bottom: px solid red; */
  transition: color 0.5s linear; /* vendorless fallback */
}

.ant-menu-horizontal > .ant-menu-item:after,
.ant-menu-horizontal > .ant-menu-submenu:after {
  transition: none;
}
.ant-picker-dropdown .ant-picker-today-btn {
  color: #1bcdb7;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 4 11px;
  background-color: #f5f5f5;
  border-radius: 5px;
  height: 40px !important;
  /* align-items: center; */
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(
    .ant-picker-cell-range-hover-end
  )
  .ant-picker-cell-inner {
  background: #f5f5f5;
  border-radius: 20px !important;
}

.ant-picker-cell .ant-picker-cell-inner {
  border-radius: 20px !important;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  border-radius: 20px !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
  border-radius: 20px !important;
}

.ant-picker-cell-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  pointer-events: none;
}

.ant-picker-cell-in-view {
  color: #1bcdb7;
}

.ant-picker-cell-in-view:disabled {
  /* color: red; */
}

/* checkboxStyles.css */
// .ant-checkbox-group .ant-checkbox-wrapper {
//   .ant-checkbox-inner {
//     border-color: #1bcdb7; /* Change this to your desired border color */
//   }

//   &.ant-checkbox-wrapper-checked {
//     .ant-checkbox-inner {
//       background-color: #1bcdb7; /* Change this to your desired checked background color */
//       border-color: #1bcdb7; /* Change this to your desired checked border color */
//     }
//   }
// }

.ant-radio-group .ant-radio-wrapper {
  .ant-radio-inner {
    border-color: #1bcdb7;
  }

  &.ant-radio-wrapper-checked {
    .ant-radio-inner {
      /* background-color: #1bcdb7; */
      border-color: #1bcdb7;
    }
  }
}

.ant-radio-inner:after {
  background-color: #1bcdb7;
  /* border-color: #1bcdb7; */
}

.ant-select-arrow {
  /* top: 70% !important; */
}

.ant-select-single {
  height: 40px;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #43bdae;
}

.ant-radio-checked .ant-radio-inner::after {
  background-color: #ffffff;
}

// .ant-checkbox-disabled .ant-checkbox-inner:after {
//   border-color: #f5f5f5;
// }

// .ant-checkbox-disabled .ant-checkbox-inner {
//   background-color: #909090;
//   border-color: #909090;
//   &:hover {
//     border-color: #909090 !important;
//   }
// }

.ant-input::-webkit-outer-spin-button,
.ant-input::-webkit-inner-spin-button {
  display: none;
}
