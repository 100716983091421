.container {
  display: flex;
  flex-direction: column;
}

.nextBtn {
  border-radius: 20px;
  margin-top: 60px;
  padding: 0px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #bdf6e4;
  @media screen and (width< 400px) {
    width: 150px;
    // margin-left: 130px;
    margin-bottom: 2rem;
  }
  @media screen and (width > 400px) and (width < 750px) {
    width: 150px;
    // margin-left: 150px;
    margin-bottom: 2rem;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    width: 200px;
    // margin-left: 330px;
    margin-bottom: 2rem;
  }
  @media screen and (width > 1200px) {
    width: 13rem;
    margin-bottom: 2rem;
    // margin-left: 680px;
  }
}

.returnHomeBtn {
  border-radius: 20px;
  margin-top: 60px;
  padding: 0px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;

  @media screen and (width< 400px) {
    width: 150px;
    // margin-left: 130px;
    margin-bottom: 2rem;
  }
  @media screen and (width > 400px) and (width < 750px) {
    width: 150px;
    // margin-left: 150px;
    margin-bottom: 2rem;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    width: 200px;
    // margin-left: 330px;
    margin-bottom: 2rem;
  }
  @media screen and (width > 1200px) {
    width: 200px;
    margin-bottom: 2rem;
    // margin-left: 680px;
  }
}

.nextBtnTextBox {
  border-radius: 20px;
  // margin-top: 60px;
  padding: 0px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: #f5f5f5;
  background-color: #bdf6e4;
  // &:hover {
  //   background-color: #bdf6e4;
  // }

  @media screen and (width< 400px) {
    width: 150px;
    // margin-left: 130px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    width: 150px;
    // margin-left: 150px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    width: 200px;
    // margin-left: 330px;
  }
  @media screen and (width > 1200px) {
    width: 200px;
    // margin-left: 680px;
  }
}
.nextBtnText {
  font-weight: bold;

  @media screen and (width< 400px) {
    font-size: 14px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 15px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    font-size: 16px;
  }
  @media screen and (width > 1200px) {
    font-size: 17px;
  }
}

.main {
  height: 82vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.mainCenter {
  height: 82vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.leftArrow {
  height: 56px;
  width: 56px;
  left: 80px;
  top: 50px;
  position: absolute;
  cursor: pointer;
}

.questionText {
  margin-top: 50px;
  margin-left: 10vw;
  margin-right: 5vw;
}

.fontSize24 {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #000000;
  padding: 10px 0px;
}

.fontSize30 {
  font-family: "Lexend";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #000000;
  padding: 10px 0px;
}

.marginDiv {
  margin: 0px 15vw;
  margin-top: -5vw;
}

.button {
  align-self: center;
  width: fit-content;
  background-color: #f5f5f5;
  display: inline-block;
  border-radius: 35px;

  margin-top: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 40px;
  padding-right: 40px;

  font-family: "Archivo";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 27px;
  letter-spacing: 0.03em;
  color: #000000;
  cursor: pointer;

  &:hover {
    background-color: #bdf6e4;
  }
}

.centerText {
  margin-top: 50px;
}

.textArea {
  height: 30vh !important;
  width: 80vw;
  align-self: center;
  border-radius: 22px;
  padding: 15px;
  margin-top: 5vh;
  margin-bottom: 10px;
  border: 1px solid #eaeaea;
  // box-shadow: 0px 4px 4px rgba(185, 185, 185, 0.25);

  &:hover {
    outline: none !important;
    border: 1px solid #eaeaea;
    // box-shadow: 0px 4px 4px rgba(112, 112, 112, 0.25);
  }
  &:focus {
    outline: none !important;
    border: 1px solid #eaeaea;
    box-shadow: 0px 4px 4px rgba(112, 112, 112, 0.25);
  }
}

@media screen and (max-width: 992px) {
  .leftArrow {
    top: 10px;
    left: 10px;
  }
  .marginDiv {
    margin: 0px 10vw;
    margin-top: unset;
  }
  .questionText {
    margin-top: 10px;
    margin-left: 50px;
  }
  .textArea {
    border-radius: 7px;
  }
}

// questionnaire section

.progressBar {
  @media screen and (width< 400px) {
    width: 220px;
    // margin-left: 20px;
    margin-bottom: 20px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    width: 300px;
    // margin-left: 20px;
    margin-bottom: 20px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    width: 500px;
    // margin-left: 50px;
    margin-bottom: 30px;
  }
  @media screen and (width > 1200px) {
    width: 600px;

    // margin-left: 100px;
    margin-bottom: 40px;
  }
}

.questionnaireName {
  font-family: "Lexend";
  font-style: normal;
  letter-spacing: 0.03em;
  font-weight: 700;
  line-height: 30px;
  text-align: center;

  @media screen and (width< 400px) {
    font-size: 13px;
    margin-left: 30px;
    margin-right: 30px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 14px;
    margin-left: 50px;
    margin-right: 50px;
    max-width: 300px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    font-size: 18px;
    margin-left: 80px;
    margin-right: 80px;
    max-width: 620px;
  }
  @media screen and (width > 1200px) {
    font-size: 18px;
    margin-left: 130px;
    margin-right: 130px;
  }
}

.descriptionOne {
  font-family: "Archivo";
  letter-spacing: 0.01em;
  margin-top: 10px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #585758;

  @media screen and (width< 400px) {
    font-size: 14px;
    margin-left: 30px;
    margin-right: 30px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 14px;
    margin-left: 50px;
    margin-right: 50px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    font-size: 15px;
    margin-left: 150px;
    margin-right: 150px;
  }
  @media screen and (width > 1200px) {
    font-size: 16px;
    margin-left: 400px;
    margin-right: 400px;
  }
}

.descriptionTwo {
  font-family: "Archivo";
  letter-spacing: 0.01em;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #585758;
  @media screen and (width< 400px) {
    font-size: 12px;
    margin-left: 30px;
    margin-right: 30px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 13px;
    margin-left: 50px;
    margin-right: 50px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    font-size: 14px;
    margin-left: 80px;
    margin-right: 80px;
  }
  @media screen and (width > 1200px) {
    font-size: 15px;
    // margin-left: 130px;

    margin-left: 130px;
    margin-right: 130px;
  }
}

.backBtn {
  position: absolute;
  @media screen and (width< 400px) {
    right: 150px;
    top: 30px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    right: 180px;
    top: 30px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    right: 320px;
    top: 33px;
  }
  @media screen and (width > 1200px) {
    right: 500px;
    top: 33px;
  }
}

.backBtnWithoutProgressbar {
  position: absolute;
  @media screen and (width< 400px) {
    right: 160px;
    top: 95px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    right: 160px;
    top: 95px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    right: 330px;
    top: 98px;
  }
  @media screen and (width > 1200px) {
    right: 580px;
    top: 98px;
  }
}

.input {
  border-radius: 22px;
  border: 1px solid #eaeaea;
  background: #fff;
  height: 10vh !important;
  width: 65vw;
  box-shadow: 0px 4px 4px 0px rgba(112, 112, 112, 0.25);
}

.DatePicker {
  background: #fff;
  border-radius: 10px;
  // margin-top: 10px;
  // margin-bottom: 20px;
  height: 90px;
  display: flex;
}

.DatePicker:active {
  border-color: #eaeaea !important;
}
