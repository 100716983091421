.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //   background-color: #f2f2f2;
}
.logo {
  height: 34px;
  width: auto;
}

.header {
  font-size: 32px;
  color: #000;
  text-align: center;
  font-weight: 600;
  //   margin: 0px;
}

.innerContainer {
  padding: 20px;

  @media screen and (width < 750px) {
    align-self: flex-start !important;
    width: 100% !important;
  }
}

.label {
  font-family: "Archivo";
  font-style: normal;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.05em;
  margin-top: 20px;
  margin-bottom: 0px;
  color: #000000;
  margin: 0px;
}

.nameInputStyle {
  background: #f2f2f2;
  border-radius: 10px;
  border: 1px solid #f5f5f5;
  height: 50px;
  width: 400px;
  font-family: "Archivo";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 10px;
  letter-spacing: 0.05em;
  color: #000000;
  margin-top: 0px;
  margin-bottom: 0px;
  @media screen and (width < 750px) {
    width: 100%;
  }
}
.submit {
  background: #1bcdb7;
  padding: 0px 50px !important;
  margin-top: 20px;
  //   height: 40px !important;
  // width: 150px !important;

  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.03em;

  /* DiveThru Colours */
  color: #ffffff;
  border: none !important;
}
.socialCointainer {
  display: flex;
}
