.container {
  padding-top: 20px;
  padding-bottom: 20px;

  display: grid;
  text-align: left;
  @media screen and (width< 400px) {
    padding-left: 0px;
    padding-right: 0px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    padding-left: 2%;
    padding-right: 2%;
  }
}
.submittedContainer {
  display: grid;
  align-items: center;
  justify-content: center;
  height: 70vh;
  width: 100vw;
  padding: 20px;
}
.topContainer {
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 50px;
  @media screen and (width< 400px) {
    padding-left: 0px;
    padding-right: 0px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    padding-left: 2%;
    padding-right: 2%;
  }
}
.title {
  color: var(--style-colours-black, #000);
  font-family: Lexend;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.05px;
  padding-top: 20px;
  @media screen and (width< 400px) {
    font-size: 22px;
  }
}

.answerDescription {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.72px;
  // padding-top: 20px;
  // padding-bottom: 40px;
  text-align: left;
  @media screen and (width< 400px) {
    font-size: 16px;
  }
}
.descriptionMini {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.72px;
  @media screen and (width< 400px) {
    font-size: 16px;
  }
}
.description {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.72px;
  padding-top: 20px;
  padding-bottom: 40px;
  @media screen and (width< 400px) {
    font-size: 16px;
  }
}
.submittedQuestion {
  color: var(--style-colours-black, #000);
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.05px;
  // padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
  @media screen and (width< 400px) {
    font-size: 22px;
  }
}
.headerTitle {
  color: var(--style-colours-black, #000);
  font-family: Lexend;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.05px;
  // padding-top: 20px;
  text-align: center;
  @media screen and (width< 400px) {
    font-size: 22px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 23px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    font-size: 26px;
  }
  @media screen and (width > 1200px) {
  }
}
.headerDescription {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.72px;
  padding-top: 20px;
  padding-bottom: 40px;
  @media screen and (width< 400px) {
    font-size: 15px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 16px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    font-size: 18px;
  }
  @media screen and (width > 1200px) {
  }
}
.label {
  color: #000;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media screen and (width< 400px) {
    font-size: 16px;
  }
}
.labelDes {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.6px;
  @media screen and (width< 400px) {
    font-size: 16px;
  }
}

.returnHomeBtn {
  border-radius: 20px;
  // margin-top: 60px;
  padding: 0px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #f5f5f5;

  @media screen and (width< 400px) {
    width: 150px;
    // margin-left: 130px;
    margin-bottom: 2rem;
  }
  @media screen and (width > 400px) and (width < 750px) {
    width: 150px;
    // margin-left: 150px;
    margin-bottom: 2rem;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    width: 200px;
    // margin-left: 330px;
    margin-bottom: 2rem;
  }
  @media screen and (width > 1200px) {
    width: 200px;
    margin-bottom: 2rem;
    // margin-left: 680px;
  }
}

.inputStyle {
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  height: 55px;
  // margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #000000;

  border-radius: 10px;
  @media screen and (width< 400px) {
    font-size: 16px;
  }
}
.DatePicker {
  height: 55px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  border-color: #f5f5f5;
  background-color: #f5f5f5;
  :global {
    .ant-picker-input > input {
      font-size: 18px !important;
    }
  }
}

.itemText {
  font-style: normal;
  font-weight: 400;
  font-size: 18px !important;
  line-height: 22px;
  /* identical to box height */
  letter-spacing: 0.05em;
  color: #000000;
  //   padding-top: 10px !important;
  @media screen and (width< 400px) {
    font-size: 16px !important;
  }
}
.radioButton {
  font-style: normal;
  font-weight: 400;
  font-size: 18px !important;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.05em;

  color: #000000;
  @media screen and (width< 400px) {
    font-size: 16px !important;
  }
}
.formTitle {
  color: var(--style-colours-black, #000);
  font-family: Lexend;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.9px;
  text-align: left;
  padding-top: 25px;
  padding-bottom: 15px;
  @media screen and (width< 400px) {
    font-size: 16px !important;
  }
}
.formDescription {
  color: #000;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.6px;
  text-align: left;
  padding-bottom: 20px;
  @media screen and (width< 400px) {
    font-size: 16px !important;
  }
}
.submitBtn {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 30px;
  background-color: #1bcdb7;
  width: 200px;
  height: 50px;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #1ce5cc;
  }
  &:active {
    background-color: #1bcdb7;
  }
  &:focus {
    background-color: #1bcdb7;
  }
  @media screen and (width< 400px) {
    font-size: 16px;
    height: 45px;
    width: 150px;
  }
}

.submit {
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 25px;
  /* identical to box height */

  letter-spacing: 0.05em;

  /* DiveThru Colours */

  color: #ffffff;
  @media screen and (width< 400px) {
    font-size: 16px;
  }
}
.completeContainer {
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 15%;
  padding-right: 15%;

  padding-bottom: 35vh;
  @media screen and (max-width: 992px) {
    padding-bottom: 20vh;
  }
}
.submitted {
  color: var(--style-colours-black, #000);
  font-family: Lexend;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.2px;
  padding-top: 50px;
  @media screen and (width< 400px) {
    font-size: 22px;
  }
}
.submittedDetails {
  color: #000;
  text-align: center;
  font-family: Archivo;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.78px;
  padding-top: 20px;
  @media screen and (width< 400px) {
    font-size: 16px;
  }
}
.createAccount {
  color: var(--dive-thru-colours, #000);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.54px;

  border-radius: 35px;
  background: var(--dive-thru-colours, #bdf6e4);
  height: 50px;
  display: grid;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  &:hover {
    background-color: #1ce5cc;
  }
  &:active {
    background-color: #1bcdb7;
  }
  &:focus {
    background-color: #1bcdb7;
  }
  @media screen and (width< 400px) {
    font-size: 12px;
    width: 170px;
  }
}
.returnHome {
  border-radius: 35px;
  background: var(--dive-thru-colours, #f5f5f5);
  height: 50px;
  width: 207px;
  display: grid;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;

  color: var(--dive-thru-colours, #000);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.54px;

  cursor: pointer;
  &:hover {
    background-color: #eaeaea;
  }
  &:active {
    background-color: #eaeaea;
  }
  &:focus {
    background-color: #eaeaea;
  }
  @media screen and (width< 400px) {
    font-size: 12px;
    width: 170px;
    // font-weight: bold;
  }
}

.saveBtn {
  border-radius: 40px;

  background-color: #1bcdb7;
  color: #fff;
  font-family: "Archivo";
  font-style: normal;
  font-weight: 600;

  // line-height: 26px;
  letter-spacing: 0.03em;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: #18b19f;
  }

  @media screen and (width< 400px) {
    padding-top: 8px;
    padding-bottom: 8px;
    width: 80px;
    font-size: 14px;
  }

  @media screen and (width > 400px) and (width < 750px) {
    padding-top: 8px;
    padding-bottom: 8px;
    width: 80px;
    font-size: 14px;
  }

  @media screen and (width > 750px) and (width < 1200px) {
    padding-top: 8px;
    padding-bottom: 8px;
    width: 90px;
    font-size: 16px;
  }

  @media screen and (width > 1200px) {
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100px;
    font-size: 18px;
  }
}
