.input{
    height: 45px;
    border-radius: 10px;
}
.submitBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background-color: #1bcdb7;
    // width: 200px;
    // height: 45px;
    border: none;
    cursor: pointer;
    &:hover {
      background-color: #1ce5cc;
    }
    &:active {
      background-color: #1bcdb7;
    }
    &:focus {
      background-color: #1bcdb7;
    }
  }
  .signInText {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    margin-bottom: 0px;
    letter-spacing: 0.06em;
  
    /* DiveThru Colours */
  
    color: #ffffff;
  }

  .dropDown {
    display: flex;
    align-items: center;
    // height: 10px;
    // margin-top: 10px;
  }