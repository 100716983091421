.main {
  height: 82vh;
  display: flex;
  flex-direction: column;
  position: relative;
}
.sideBar {
  background-color: #1bcdb7;
  height: auto;
  // @media screen and (width< 400px) {
  // }
  // @media screen and (width > 400px) and (width < 750px) {
  // }
  // @media screen and (width > 750px) and (width < 1200px) {
  //   height: 82vh;
  // }
  // @media screen and (width > 1200px) {
  //   height: 82vh;
  // }
}
// .header {
//   border-bottom: 1px solid #e5e7eb;
//   height: 100vh;
//   display: flex;
//   align-items: center;
// }
.leftArrow {
  height: 56px;
  width: 56px;
  left: 80px;
  top: 50px;
  position: absolute;
  cursor: pointer;
}
.questionText {
  margin-top: 50px;
  margin-left: 10vw;
  margin-right: 5vw;
}
.fontSize30 {
  font-family: "Lexend";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #000000;
  padding: 10px 0px;
}
@media screen and (max-width: 992px) {
  .leftArrow {
    top: 10px;
    left: 10px;
  }
  .marginDiv {
    margin: 0px 10vw;
    margin-top: unset;
  }
  .questionText {
    margin-top: 10px;
    margin-left: 50px;
  }
}

.unSelectedSingleOption {
  background: #f5f5f5;
  border-radius: 15px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  // &:hover {
  //   background-color: #bdf6e4;
  // }
  @media screen and (width< 400px) {
    width: 230px;
    // margin-left: 90px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    width: 300px;
    // margin-left: 80px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    width: 420px;
    // margin-left: 220px;
  }
  @media screen and (width > 1200px) {
    width: 500px;
    // margin-left: 530px;
  }
}

.selectedSingleOption {
  background: #bdf6e4;
  border-radius: 15px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  // &:hover {
  //   background-color: #bdf6e4;
  // }
  @media screen and (width< 400px) {
    width: 230px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    width: 300px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    width: 420px;
  }
  @media screen and (width > 1200px) {
    width: 500px;
  }
}

.unSelectedMultiOption {
  background: #f5f5f5;
  // &:hover {
  //   background-color: #bdf6e4;
  // }
  border-radius: 15px;
  margin-top: 20px;
  margin-right: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (width< 400px) {
  }
  @media screen and (width > 400px) and (width < 750px) {
  }
  @media screen and (width > 750px) and (width < 1200px) {
  }
  @media screen and (width > 1200px) {
    height: 70px;
  }
}

.unSelectedSignleLineMultiOption {
  background: #f5f5f5;
  border-radius: 15px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  // &:hover {
  //   background-color: #bdf6e4;
  // }
  @media screen and (width< 400px) {
    width: 230px;
    // margin-left: 90px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    width: 300px;
    // margin-left: 80px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    width: 420px;
    // margin-left: 220px;
  }
  @media screen and (width > 1200px) {
    width: 500px;
    // margin-left: 530px;
  }
}

.selectedMultiOption {
  background: #bdf6e4;
  // &:hover {
  //   background-color: #bdf6e4;
  // }
  border-radius: 15px;
  margin-top: 20px;
  margin-right: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (width< 400px) {
  }
  @media screen and (width > 400px) and (width < 750px) {
  }
  @media screen and (width > 750px) and (width < 1200px) {
  }
  @media screen and (width > 1200px) {
    height: 70px;
  }
}

.selectedSingleLineMultiOption {
  background: #bdf6e4;
  border-radius: 15px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  // &:hover {
  //   background-color: #bdf6e4;
  // }
  @media screen and (width< 400px) {
    width: 230px;
    // margin-left: 90px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    width: 300px;
    // margin-left: 80px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    width: 420px;
    // margin-left: 220px;
  }
  @media screen and (width > 1200px) {
    width: 500px;
    // margin-left: 530px;
  }
}

.multiOptionContainer {
  @media screen and (width< 400px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    padding-left: 70px;
    padding-right: 70px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    padding-left: 80px;
    padding-right: 80px;
  }
  @media screen and (width > 1200px) {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.nextBtn {
  border-radius: 20px;
  margin-top: 60px;
  margin-bottom: 30px;
  padding: 0px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: #f5f5f5;
  // &:hover {
  //   background-color: #bdf6e4;
  // }
  background-color: #bdf6e4;
  @media screen and (width< 400px) {
    width: 150px;
    // margin-left: 130px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    width: 150px;
    // margin-left: 150px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    width: 200px;
    // margin-left: 330px;
  }
  @media screen and (width > 1200px) {
    width: 200px;
    // margin-left: 680px;
  }
}
.nextBtnText {
  font-weight: bold;

  @media screen and (width< 400px) {
    font-size: 14px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 15px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    font-size: 16px;
  }
  @media screen and (width > 1200px) {
    font-size: 17px;
  }
}

.footerBoldText {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: 0.03em;

  color: #000000;
}

.footerText {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: 0.03em;

  color: #000000;
}

.text {
  // overflow: scroll;
  text-overflow: ellipsis;
  justify-content: center;
  display: -webkit-box;
  line-height: 16px; /* fallback */
  max-height: 200px; /* fallback */
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  width: 200px;
}

.selectedPaymentOption {
  background: #bdf6e4;
  border-radius: 15px;
  margin-top: 15px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  cursor: pointer;
  // &:hover {
  //   background-color: #bdf6e4;
  // }
  @media screen and (width< 400px) {
    width: 300px;
    // margin-left: 35px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    width: 310px;
    // margin-left: 70px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    width: 560px;
    // margin-left: 120px;
  }
  @media screen and (width > 1200px) {
    width: 800px;
    // margin-left: 380px;
  }
}

.unSelectedPaymentOption {
  background: #f5f5f5;
  border-radius: 15px;
  margin-top: 15px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  cursor: pointer;
  // &:hover {
  //   background-color: #bdf6e4;
  // }
  @media screen and (width< 400px) {
    width: 300px;
    // margin-left: 35px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    width: 310px;
    // margin-left: 70px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    width: 560px;
    // margin-left: 120px;
  }
  @media screen and (width > 1200px) {
    width: 800px;
    // margin-left: 380px;
  }
}

.navItem {
  color: #f5f5f5;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected.customclass {
  background-color: green; /*Overriden property*/
}

.bookSessionBtn {
  background-color: #883dbf;
  border-radius: 40px;
  padding-top: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (width< 400px) {
    width: 200px;
    margin-top: 10px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    width: 230px;
    margin-top: 10px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    width: 200px;
  }
  @media screen and (width > 1200px) {
    width: 200px;
  }
}

.header {
  // position: sticky;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  @media screen and (width< 400px) {
    height: 18vh;
    // padding-left: 20px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    height: 18vh;
    // padding-left: 20px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    height: 18vh;
    // padding-left: 60px;
  }
  @media screen and (width > 1200px) {
    height: 18vh;
    // padding-left: 60px;
  }
}

.logo {
  cursor: pointer;
  width: auto;
  @media screen and (width< 400px) {
    height: 25px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    height: 35px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    height: 35px;
  }
  @media screen and (width > 1200px) {
    height: 56px;
  }
}

.logoCol {
  @media screen and (width< 400px) {
    text-align: center;
  }
  @media screen and (width > 400px) and (width < 750px) {
    text-align: center;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    text-align: left;
  }
  @media screen and (width > 1200px) {
    text-align: left;
  }
}

.menuCol {
  text-align: right;
}

.signOutBtn {
  background-color: #43bdae;

  @media screen and (width < 375px) {
    padding: 3px 5px;
    border-radius: 20px;
    font-size: 10px;
  }
  @media screen and (width > 375px) and (width < 400px) {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    font-size: 13px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    font-size: 13px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    font-size: 13px;
  }
  @media screen and (width > 1200px) {
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 20px;
    font-size: 18px;
  }
}

.menu {
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (width< 400px) {
    line-height: 25px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    line-height: 25px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    line-height: 25px;
    justify-content: flex-end;
    padding-right: 20px;
  }
  @media screen and (width > 1200px) {
    line-height: 35px;
    justify-content: flex-end;
    padding-right: 20px;
  }
}

.navItem {
  border: 0px;

  @media screen and (width< 400px) {
    padding-left: 0px !important;
  }
  @media screen and (width > 400px) and (width < 750px) {
    padding-left: 0px !important;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    font-size: 15px;
  }
  @media screen and (width > 1200px) {
    font-size: 24px;
  }
}

.navItem::before {
  border: 0px;
}

.navItemText {
  &:active {
    color: #000000;
  }
  &:hover {
    color: #000000 !important;
  }
  font-family: "Archivo";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  // letter-spacing: 0.07em;

  color: #000000;

  @media screen and (width< 400px) {
    font-size: 12px;
  }
  @media screen and (width > 400px) and (width < 750px) {
    font-size: 13px;
  }
  @media screen and (width > 750px) and (width < 1200px) {
    font-size: 15px;
  }
  @media screen and (width > 1200px) {
    font-size: 24px;
  }
}

.sideBarImg {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert {
  background-color: #1bcdb7;
}
